<template>
    <basic-layout>
      <div class="sp-tile">
        <h2 class="text-center mt-5 mb-5">
            {{this.$t("welcome.pageHeader")}}
        </h2>
  
  
        <b-container class="tile-content">
          <div class="cards-container">
            <b-container fluid>
              <b-row align-h="center">
                <h3>{{multiFactorCards.title}}</h3>
              </b-row>
              <b-row align-h="center">
                <b-col v-for="(card, i) in multiFactorCards.cards" cols="12" sm="4" :key="i">
                  <Tile v-bind="card"/>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="cards-container">
            <b-container fluid>
              <b-row align-h="center">
                <h3>{{investmentTheories.title}}</h3>
              </b-row>
              <b-row align-h="center">
                <b-col v-for="(card, i) in investmentTheories.cards" cols="12" sm="4" :key="i">
                  <Tile v-bind="card"/>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="cards-container">
            <b-container fluid>
              <b-row align-h="center">
                <h3>{{processAutomation.title}}</h3>
              </b-row>
              <b-row align-h="center">
                <b-col v-for="(card, i) in processAutomation.cards" cols="12" sm="4" :key="i">
                  <Tile v-bind="card"/>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-container>
      </div>
    </basic-layout>
  </template>
  
  <script>
  import { getBaseURL, getFactorInvestingURL } from "../utils/helper";
  import { mapActions, mapGetters } from "vuex";
  import { ALL_PRODUCTS } from "../utils/constants";
  import BasicLayout from "../layouts/BasicLayout.vue";
  import MainAlert from "../components/alerts/MainAlert.vue";
  import Tile from "../components/Tile.vue";
  
  export default {
    components: { BasicLayout, Tile },
    data() {
      return {
        ALL_PRODUCTS,
        multiFactorCards: {
          title: this.$t("welcome.multiFactorTitle"),
          cards: [
            {
              title: this.$t("welcome.factorInvestingTitle"), 
              text: this.$t("welcome.factorInvestingDescription"),
              link: this.getFactorInvestingURL(),
              role: ALL_PRODUCTS.factorInvesting,
              bg: "#C4E9E7"
            },
            {
              title: this.$t("welcome.stockRankingTitle"), 
              text: this.$t("welcome.stockRankingDescription"), 
              link: "/stockRanking",
              role: ALL_PRODUCTS.stockRanking,
              bg: "#F7C4A5"
            },
            {
              title: this.$t("welcome.investmentStrategiesTitle"), 
              text: this.$t("welcome.investmentStrategiesDescription"), 
              link: "/stockPickingLab",
              role: ALL_PRODUCTS.stockPickingLab,
              bg: "#E3DAFB"
            }
          ]
        },
        investmentTheories: {
          title: this.$t("welcome.investmentTheoriesTitle"), 
          cards: [
            {
              title: this.$t("welcome.financialArticlesTitle"), 
              text: this.$t("welcome.financialArticlesDescription"), 
              link: `${this.getBaseURL(false, true)}/optimization-fe/marketSentiment`,
              role: ALL_PRODUCTS.marketSentiment,
              bg: "#E8E2E3"
            },
            {
              title: this.$t("welcome.portfolioManagerTitle"), 
              text: this.$t("welcome.portfolioManagerDescription"), 
              link: `${this.getBaseURL(false, true)}/optimization-fe/portfolioManager`,
              role: ALL_PRODUCTS.portfolioOptimization,
              bg: "#B1E0E7"
            },
            {
              title: this.$t("welcome.patternTradingTitle"), 
              text: this.$t("welcome.patternTradingDescription"), 
              link: "/patternLab",
              role: ALL_PRODUCTS.patternLab,
              bg: "#FBDADA"
            }
          ]
        },
        processAutomation: {
          title: this.$t("welcome.processAutomationTitle"), 
          cards: [
            {
              title: this.$t("welcome.taxesTitle"), 
              text: this.$t("welcome.taxesDescription"), 
              link: "/taxes",
              role: ALL_PRODUCTS.tax,
              bg: "#FBDADA"
            }
          ]
        },
      };
    },
    methods: {
      ...mapActions(["storeUserInterest"]),
      getBaseURL,
      getFactorInvestingURL,
      handleRebalancePortfolio() {
          const module = this.currentRouteName;
          this.storeUserInterest(module).then(() => {
              this.$toast.info({
                  component: MainAlert,
                  props: {
                      message: this.$i18n.t("inPreparation.success"),
                  },
              });
          }).catch(() => {
              this.$toast.error({
                  component: MainAlert,
                  props: {
                      message: this.$i18n.t("inPreparation.error"),
                  },
              });
          });
      }
    },
    computed: {
      ...mapGetters({ user: "getUser" }),
      isPatternLabAvailable() {
        return !this.user.permissions.includes(ALL_PRODUCTS.patternLab)
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .sp-tile {
    .header {
      margin: 0 -25px;
      text-align: center;
      margin-bottom: toRem(55);
      a {
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: toRem(18);
        margin-left: toRem(20);
        h2 {
          margin-bottom: 0;
          margin-left: toRem(10);
        }
      }
      h2 {
        margin-bottom: toRem(18);
        font-weight: 700;
        font-size: toRem(24);
        font-family: Roboto;
      }
      h3 {
        font-family: Roboto;
        font-size: toRem(16);
        font-weight: 700;
        margin-bottom: toRem(18);
      }
      .module-desc {
        padding: toRem(16) 0;
        background: $blueBackground;
        p {
          max-width: toRem(720);
          margin: 0 auto;
        }
      }
    }
    .tilecontent {
      position: relative;
      .next-icon {
        position: absolute;
        top: 50%;
        right: -75px;
      }
      .prev-icon {
        position: absolute;
        top: 50%;
        left: -75px;
      }
    }
    padding-bottom: toRem(50);
    @media screen and (max-width: $lg) {
      .wz-content {
        .next-icon {
          right: 5px;
        }
        .prev-icon {
          left: 5px;
        }
        .next-icon,
        .prev-icon {
          position: fixed;
        }
      }
    }
  }
  
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: calc((100% - 900px) / 3);
    margin-bottom: toRem(18);
    row-gap: toRem(32);
    @media screen and (max-width: $lg) {
      column-gap: calc((100% - 675px) / 2);
    }
    @media screen and (max-width: $md) {
      column-gap: calc(100% - 450px);
    }
    @media screen and (max-width: $sm) {
      justify-content: center;
    }
  }
  </style>
  