<template>
  <div>
    <nav-bar />
    <div id="wrapper">
      <b-container fluid id="main-content">
        <div v-if="title" class="main-title">{{ title }}</div>
        <slot />
      </b-container>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/navs/MainNavigation.vue";
export default {
  name: "BasicLayout",
  components: { NavBar },
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
#main-content {
  padding: 25px;
  margin-top: 52px;
}

#wrapper {
  display: flex;
  height: calc(100vh - 52px);
}
@media only screen and (max-width: 800px){
  #wrapper{
    #main-content{
      margin-top: toRem(75);
    }
  }
}
</style>